<stb-form [label]="'afpFormField.label' | fms" [hint]="'afpFormField.tip' | fms">
  @if (radioButtons$ | async; as radioButtons) {
    <stb-radio-buttons
      [horizontal]="false"
      [group]="radioButtons"
      (valueChanged)="updateAfp($event)"
      data-trackid="AfpFormFieldComponent-afpValueChanged-radioButton"
    >
    </stb-radio-buttons>
  }
</stb-form>
